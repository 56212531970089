import TeamAiden from "../images/members/team_aiden.jpeg";
import TeamAree from "../images/members/team_aree.jpg";
import TeamArjun from "../images/members/team_arjun.jpeg";
import TeamAustin from "../images/members/team_austin.jpeg";
import TeamBen from "../images/members/team_ben.jpeg";
import TeamCita from "../images/members/team_cita.jpeg";
import TeamClement from "../images/members/team_clement.png";
import TeamCrystal from "../images/members/team_crystal.jpeg";
import TeamCrystalK from "../images/members/team_crystalk.jpg";
import TeamEthan from "../images/members/team_ethan.jpeg";
import TeamFaezrah from "../images/members/team_faezrah.jpg";
import TeamFaisal from "../images/members/team_faisal.jpg";
import TeamGabriel from "../images/members/team_gabriel.jpeg";
import TeamIssaret from "../images/members/team_issaret.jpg";
import TeamJames from "../images/members/team_james.jpeg";
import TeamJeannette from "../images/members/team_jeannette.jpg";
import TeamLouis from "../images/members/team_louis.jpeg";
import TeamMikel from "../images/members/team_mikel.jpeg";
import TeamSandra from "../images/members/team_sandra.jpeg";
import TeamVenus from "../images/members/team_venus.jpeg";

export const memberList = [
  {
    name: "Aiden Melone",
    role: "Student at Georgia Institute of Technology",
    imageUrl: TeamAiden,
    linkedinUrl: "https://www.linkedin.com/in/aiden-melone/",
    about:
      "Aiden is a computer science student at Georgia Tech seeking to graduate with threads in people and intelligence by May 2024. His personal philosophy regarding computer science is that while it's great to understand how to make something, it's just as important to understand who you're making something for - which is why he is also seeking a minor in psychology. Aiden's past experiences includes participation within a variety of different hackathons, android app development, and UI design.",
    location: "Atlanta Metropolitan Area, United States",
  },
  {
    name: "Aree Oh",
    role: "Software Development Engineer",
    imageUrl: TeamAree,
    linkedinUrl: "https://www.linkedin.com/in/aree-oh",
    about: "",
    location: "Korea",
  },
  {
    name: "Arjun Verma",
    role: "Student at Georgia Institute of Technology",
    imageUrl: TeamArjun,
    linkedinUrl: "https://www.linkedin.com/in/arjun-verma-3875151b5/",
    about:
      "Arjun is a dedicated and passionate software developer with strong Web/Mobile development, Embedded programming, Object Oriented programming, collaboration and leadership skills as well as cross-curricular strengths in computer-aided design. Currently seeking a Fall 2021, Spring 2022 or Summer 2022 co-op/internship.",
    location: "Atlanta, Georgia, United States",
  },
  {
    name: "Austin Polin",
    role: "Front End Developer",
    imageUrl: TeamAustin,
    linkedinUrl: "https://www.linkedin.com/in/austin-kisokau-polin-38b19743/",
    about:
      "Austin has just completed his Masters in Architecture and is now doing his Doctor of Philosophy in Project Management online, at the Atlantic International University. He is currently undertaking a Master of Philosophy (MPhil) at the PNG University of Technology. He is a registered Architect with the PNG Board of Architects. He has more than 10 years of experience in Design, Documentation and Project Managment. He specialises in the area of Digital Design and Vernacular Architecture. He also graduated with PG Certificate in Communication of Science and Technology in April 2013 at the PNG University of Technology. He completed his Bachelor’s Degree in Architecture in 2002 and Diploma in Architecture in 2000.",
    location: "Morobe Province, Papua New Guinea",
  },
  {
    name: "Benjamin Roe",
    role: "Head of Product at Yabble",
    imageUrl: TeamBen,
    linkedinUrl: "https://www.linkedin.com/in/benjamin-roe-626851100/",
    about:
      "Benjamin is an innovator who has his sights set on success. He wants to change the way we interact with technology and the way it interacts with us.",
    location: "Auckland, New Zealand",
  },
  {
    name: "Cita Audia Ahlunaza",
    role: "Entrepreneur & Digital Innovator",
    imageUrl: TeamCita,
    linkedinUrl: "https://www.linkedin.com/in/citaahlunaza/",
    about: "",
    location: "Indonesia",
  },
  {
    name: "Clement Liang",
    role: "Software Engineer",
    imageUrl: TeamClement,
    linkedinUrl: "https://www.linkedin.com/in/chunchiehliang/",
    about: "",
    location: "Chinese Taipei",
  },
  {
    name: "Crystal Kewe",
    role: "Technology Entrepreneur",
    imageUrl: TeamCrystalK,
    linkedinUrl: "https://www.linkedin.com/in/crystal-kewe-b7382a185",
    about: "-",
    location: "Papua New Guinea",
  },
  {
    name: "Ethan Kang",
    role: "Student at Georgia Institute of Technology",
    imageUrl: TeamEthan,
    linkedinUrl: "https://www.linkedin.com/in/ethan-kang/",
    about:
      "Ethan is strongly motivated, passionate computer science student interested in the areas of machine learning, human-computer interactions, computational social science, and software development. Currently seeking internship opportunities.",
    location: "Atlanta, Georgia, United States",
  },
  {
    name: "Faezrah Rizalman",
    role: "Technologist",
    imageUrl: TeamFaezrah,
    linkedinUrl: "https://www.linkedin.com/in/frizalman",
    about: "-",
    location: "Malaysia",
  },
  {
    name: "Faisal Ariff",
    role: "Technology Entrepreneur",
    imageUrl: TeamFaisal,
    linkedinUrl: "https://www.linkedin.com/in/faisalariff",
    about: "-",
    location: "Malaysia",
  },
  {
    name: "Gabriel Billones Jr",
    role: "Trainer, Speaker, Facilitator, & Consultant",
    imageUrl: TeamGabriel,
    linkedinUrl: "https://www.linkedin.com/in/gabrielbillones",
    about: "",
    location: "The Philippines",
  },
  {
    name: "Hoo Xing Yu (Crystal)",
    role: "Full Stack Developer",
    imageUrl: TeamCrystal,
    linkedinUrl: "https://www.linkedin.com/in/crystal-hoo-xy/",
    about:
      "Crystal is passionate about coding and she likes to discover and explore new technologies. She also likes to join different Hackathons.",
    location: "Selangor, Malaysia",
  },
  {
    name: "Issaret Prachitmutita",
    role: "Product Manager",
    imageUrl: TeamIssaret,
    linkedinUrl: "https://www.linkedin.com/in/issaret/",
    about: "-",
    location: "Bangkok, Thailand",
  },
  {
    name: "Jeannette Goon",
    role: "Writer",
    imageUrl: TeamJeannette,
    linkedinUrl: "https://realhumangirl.com/",
    about: "-",
    location: "Malaysia",
  },
  {
    name: "Louis Ong",
    role: "Software Engineer",
    imageUrl: TeamLouis,
    linkedinUrl: "https://www.linkedin.com/in/louis-ong-bh/",
    about:
      "Louis has an extensive experience in building end-to-end solutions and he is skilled at Full Stack Web Development, Hybrid/Native Mobile App Development and Crafting system architecture of deliverable projects.",
    location: "Kuala Lumpur, Malaysia",
  },
  {
    name: "Mikel Carozzi",
    role: "Senior Software Engineer",
    imageUrl: TeamMikel,
    linkedinUrl: "https://www.linkedin.com/in/mikel-carozzi/",
    about: "-",
    location: "Chile",
  },
  {
    name: "James Tsangarides",
    role: "Student at Georgia Institute of Technology",
    imageUrl: TeamJames,
    linkedinUrl: "https://www.linkedin.com/in/jtsangarides/",
    about: "-",
    location: "Atlanta, Georgia, United States",
  },
  {
    name: "Sandra Oveissi",
    role: "Front End Developer at World Vision",
    imageUrl: TeamSandra,
    linkedinUrl: "https://www.linkedin.com/in/sandra-oveissi-6a26a68b/",
    about:
      "Software Development is not only Sandra's major, but her strong passion. What Sandra loves about Technology, is to make this interactive world a better place through applying engineering principles in order to solve real-world problems. Attention to details and consistency are her key principles and she does welcome challenges and she is always looking to learn.",
    location: "Melbourne, Australia",
  },
  {
    name: "Venus Izadi",
    role: "Solution Architect at Red Hat",
    imageUrl: TeamVenus,
    linkedinUrl: "https://www.linkedin.com/in/venus-izadi/",
    about:
      "Solutions Architect at Red Hat - the leading provider of enterprise open source technologies that deliver high-performing Linux, cloud, container, and Kubernetes solutions via a community-powered approach. In addition to extensive experience leading the design of innovative business solutions, Venus has also served as a Lead Organizer for the NASA International Space Apps Challenge to help NASA on its mission to foster innovation through a diversity of perspectives. She is also a founding member of the APEC App Challenge alumni association and serves as a mentor and trainer for teams in hackathons and conferences to empower future engineers.",
    location: "New York, United States",
  },
];
