import { navigate } from "gatsby-link";
import React, { Component } from "react";
import { memberList } from "../../utils/memberList";
import Footer from "../Footer";
import Header from "../Header";
import SEO from "../Seo";
import ProfileDetails from "./ProfileDetails";
import ProfileHeader from "./ProfileHeader";

interface Props {
  pageContext: {
    member: string;
  };
}

interface State {
  authenticated: boolean;
  selectedMember: {
    name: string;
    role: string;
    imageUrl: string;
    linkedinUrl: string;
    about: string;
  };
}

export default class memberProfile extends Component<Props> {
  state: State = {
    authenticated: false,
    selectedMember: {
      name: "",
      role: "",
      imageUrl: "",
      linkedinUrl: "",
      about: "",
    },
  };

  componentDidMount = async () => {
    const authItem = await window.localStorage.getItem("dmap-auth");
    if (authItem === "Demo Authenticated") {
      this.setState({ authenticated: true });
    } else {
      navigate("/auth");
    }

    memberList.map((eachMember) => {
      if (eachMember.name === this.props.pageContext.member) {
        this.setState({
          selectedMember: eachMember,
        });
      }
      return null;
    });
  };

  render() {
    if (!this.state.authenticated) {
      return null;
    }

    if (!this.state.selectedMember.name) {
      return null;
    }

    return (
      <div className="relative bg-white overflow-hidden">
        <SEO
          title={`DMAP - ${this.state.selectedMember.name}`}
          description={this.state.selectedMember.about}
        />
        <Header />
        <ProfileHeader
          image={this.state.selectedMember.imageUrl}
          name={this.state.selectedMember.name}
        />
        <ProfileDetails />
        <Footer />
      </div>
    );
  }
}
